import { Button, Space } from 'antd'
import { useEffect, useRef, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import cn from 'utils/classnames'
import useIsTouchDevice from 'utils/useIsTouchDevice'
import Animate from './animate'

export interface EnterButtonProps {
  visible?: boolean
  btnClassName?: string
  onClick?: (e?: any) => void
  enterEventListener?: boolean
  disabled?: boolean
  children?: React.ReactNode
  className?: string
  loading?: boolean
}

export default function EnterButton(props: EnterButtonProps) {
  const intl = useIntl()
  const { visible = true, disabled, children, className = '', btnClassName = '', enterEventListener = true } = props
  const btnRef = useRef<HTMLButtonElement>(null)
  const clickable = !props.loading && !disabled && visible
  const touchDevice = useIsTouchDevice()

  useEffect(() => {
    const handleClick = ({ key, keyCode, code }: KeyboardEvent) => {
      const isEnter = code === 'Enter' || key === 'Enter' || keyCode === 13
      if (isEnter && clickable) {
        btnRef?.current?.click?.()
      }
    }
    if (process.browser && enterEventListener) window.addEventListener('keypress', handleClick, false)
    return () => {
      if (process.browser && enterEventListener) window.removeEventListener('keypress', handleClick, false)
    }
  }, [visible, disabled, clickable, enterEventListener])

  // const handleButtonKeyDown: ButtonProps['onKeyDown'] = (e) => {
  //   // when button is focused
  //   if (e.keyCode === 13 && e.preventDefault) {
  //     e.preventDefault()
  //     if (e.keyCode === 13 && clickable && btnRef.current) {
  //       btnRef.current.click()
  //       console.warn('handle button down enter event')
  //     }
  //   }
  // }

  const handleClickEvent = (e) => {
    e.preventDefault()
    if (clickable) {
      props.onClick(e)
    }
  }

  return (
    <Animate animationIn="fadeInUp" animationOut="fadeOutDown" visible={visible} animateOnMount={false}>
      <div>
        <Space
          className={cn(className, 'select-none', {
            'pointer-events-none ': !visible,
            'opacity-25': visible && !clickable,
          })}
        >
          <Button
            size="large"
            ref={btnRef}
            type="primary"
            block
            className={cn('bg-primary', btnClassName, {
              'pointer-events-none select-none': !visible,
            })}
            // onKeyDown={handleButtonKeyDown}
            disabled={!clickable}
            onClick={handleClickEvent}
          >
            {children || intl.formatMessage({ id: 'ok' })}
          </Button>
          <span className={cn('axe-ignore text-primary opacity-75 text-sm not-sr-only', touchDevice ? 'hidden' : 'md:block')}>
            <FormattedMessage
              id="press-enter"
              values={{
                b: (...chunks) => <b>{chunks}</b>,
              }}
            />
          </span>
        </Space>
      </div>
    </Animate>
  )
}
