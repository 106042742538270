import { Button, Checkbox, Drawer, Modal } from 'antd'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import EnterButton from 'components/enter-button'
import Heading from 'components/heading'
import LocaleSwitcher from 'components/locale-switch'
import Cookies from 'js-cookie'
import { useEffect, useState } from 'react'
import { FiExternalLink } from 'react-icons/fi'
import { useIntl } from 'react-intl'
import { USER_TYPES } from 'typings/kenai/interfaces'
import cn from 'utils/classnames'
import PdfReader from '../pdf-reader'

export interface WelcomeViewProps {
  onAgreementContinue: () => void
  onAgreementAcceptToggle: (checked: boolean) => void
  logo: string
  entityName: string
  agreement: string
  mandatoryAgreementRead: boolean
  kioskMode: boolean
  userType: USER_TYPES
  headingText?: string
  subHeadingText?: string
  agreementLinkText?: string
  align?: 'left'
  buttonType?: 'continueButton'
  disabled?: boolean
  hideCompanyLogoOnLanding?: boolean
}

export default function WelcomeView(props: WelcomeViewProps) {
  const [agreementConfirmed, setAgreementConfirmed] = useState(false)
  const [visible, setVisible] = useState(false)
  const [mandatoryAgreementReadRequired, setMandatoryAgreementReadRequired] = useState(false)
  const [agreementShowFromEnterButton, setAgreementShowFromEnterButton] = useState(false)
  const { lg } = useBreakpoint()
  const intl = useIntl()

  useEffect(() => {
    if (props.kioskMode) {
      if (props.mandatoryAgreementRead) {
        setMandatoryAgreementReadRequired(true)
      }
    } else {
      const hasConfirmedAgreement = Cookies.get('agreementConfirmed')
      if (!hasConfirmedAgreement) {
        if (props.mandatoryAgreementRead) {
          setMandatoryAgreementReadRequired(true)
        }
      } else {
        setAgreementConfirmed(true)
      }
    }
  }, [props.kioskMode, props.mandatoryAgreementRead])

  const handleCloseAgreementAcceptance = () => {
    Cookies.set('agreementConfirmed', false)
    setAgreementConfirmed(false)
    if (props.mandatoryAgreementRead) {
      setMandatoryAgreementReadRequired(true)
    }
    if (visible) setVisible(false)
  }

  const handleConfirmAgreement = () => {
    Cookies.set('agreementConfirmed', true)
    setAgreementConfirmed(true)
    setMandatoryAgreementReadRequired(false)
    if (visible) setVisible(false)
    if (agreementShowFromEnterButton) {
      props.onAgreementContinue()
    }
  }

  const handleToggleConfirmed = (e) => {
    Cookies.set('agreementConfirmed', e.target.checked)
    setAgreementConfirmed(e.target.checked)
    props.onAgreementAcceptToggle?.(e.target.checked)
    if (props.mandatoryAgreementRead && !e.target.checked) {
      setMandatoryAgreementReadRequired(true)
    }
  }

  const handleEnterButtonClicked = () => {
    if (props.mandatoryAgreementRead) {
      if (agreementConfirmed) {
        props.onAgreementContinue()
      } else {
        setAgreementShowFromEnterButton(true)
        setVisible(true)
      }
    } else {
      props.onAgreementContinue()
    }
  }

  const AgreementText = () => (
    <span className="text-lg break-words leading-none">
      <span className={cn('mr-1', props.disabled ? 'text-gray-900 opacity-90' : 'text-inherit')}>
        I have read, understood and accepted the
      </span>
      <a
        tabIndex={-1}
        role="button"
        onClick={() => setVisible(true)}
        onKeyDown={(e) => (e.key === '15' ? setVisible(true) : null)}
        type="link"
        className={cn('px-0 font-medium text-primary space-x-1 text-lg py-0 break-words', {
          'opacity-75 hover:opacity-100': props?.disabled,
        })}
        style={{ lineHeight: 'inherit' }}
      >
        <span>{props.agreementLinkText || 'agreement'}</span> <FiExternalLink className="inline-block" />
      </a>
    </span>
  )

  const content = (
    <div className="relative h-full">
      <PdfReader url={props.agreement} />
      <div className="absolute bottom-0 right-0 z-10 pr-8 pb-5">
        <Button size="large" type="primary" className="bg-primary rounded-md opacity-75 hover:opacity-100" onClick={handleConfirmAgreement}>
          Accept
        </Button>
      </div>
    </div>
  )

  return (
    <main
      className={cn('space-y-2 w-full', {
        'text-center': props.align !== 'left',
      })}
    >
      {!props.hideCompanyLogoOnLanding && (
        <img
          src={props.logo}
          alt={props.entityName}
          className={cn('w-64 max-w-full  mb-2 md:mb-4', props.align !== 'left' ? 'mx-auto' : 'mr-auto')}
        />
      )}

      <div className="mb-2 agreement md:mb-4">
        <Heading subHeading={props.subHeadingText}>
          {props.headingText || intl.formatMessage({ id: `welcome-title-${props.userType}` })}
        </Heading>
        {!mandatoryAgreementReadRequired || agreementConfirmed ? (
          <Checkbox onChange={handleToggleConfirmed} checked={agreementConfirmed} disabled={props.disabled}>
            <AgreementText />
          </Checkbox>
        ) : (
          <AgreementText />
        )}
      </div>
      {/* <Spacer y={3} /> */}
      <div className="action-container pt-8">
        {props.buttonType === 'continueButton' ? (
          <Button disabled={!(agreementConfirmed || mandatoryAgreementReadRequired)} type="primary" onClick={handleEnterButtonClicked}>
            Continue
          </Button>
        ) : (
          <EnterButton
            visible={agreementConfirmed || mandatoryAgreementReadRequired}
            onClick={handleEnterButtonClicked}
            className="capitalize"
          >
            {intl.formatMessage({ id: 'start-now' })}
          </EnterButton>
        )}
      </div>

      <LocaleSwitcher fixed />

      {lg ? (
        <Modal
          visible={visible}
          onCancel={handleCloseAgreementAcceptance}
          centered
          closable
          title={'Employee Agreement'}
          className="w-auto my-auto rounded-md overflow-hidden pb-0"
          wrapClassName=""
          bodyStyle={{ padding: 0 }}
          footer={false}
        >
          {content}
        </Modal>
      ) : (
        <Drawer
          visible={visible}
          onClose={handleCloseAgreementAcceptance}
          title={'Employee Agreement'}
          height="100%"
          placement="bottom"
          bodyStyle={{ padding: 0 }}
        >
          {content}
        </Drawer>
      )}

      <style jsx>
        {`
          .action-container {
            min-height: 40px;
          }
        `}
      </style>
    </main>
  )
}
