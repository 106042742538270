import { CharacterMap, SpecialZoomLevel, Viewer, Worker } from '@react-pdf-viewer/core'

import { defaultLayoutPlugin, ToolbarProps, ToolbarSlot } from '@react-pdf-viewer/default-layout'

import { Button } from 'antd'
import { ReactElement, ReactNode } from 'react'
import { AiOutlineDownload, AiOutlineZoomIn, AiOutlineZoomOut } from 'react-icons/ai'
import { BsChevronDown, BsChevronUp } from 'react-icons/bs'
import cn from 'utils/classnames'

const renderToolbar = (Toolbar: (props: ToolbarProps) => ReactElement, extra: ReactNode = null) => (
  <Toolbar>
    {(slots: ToolbarSlot) => {
      const { CurrentPageInput, GoToNextPage, GoToPreviousPage, NumberOfPages, ZoomIn, ZoomOut, Zoom, Download } = slots
      return (
        <div className="flex justify-between items-center space-x-2">
          <div className="space-x-1 flex items-center">
            <GoToPreviousPage>
              {(props) => (
                <Button
                  type="link"
                  className={cn({
                    'text-primary': !props.isDisabled,
                    'text-gray-400': props.isDisabled,
                  })}
                  onClick={props.onClick}
                  icon={<BsChevronUp />}
                  disabled={props.isDisabled}
                  aria-label="Previous Page"
                />
              )}
            </GoToPreviousPage>
            <div className="space-x-1 flex items-center">
              <CurrentPageInput /> <span>/</span>
              <span>
                <NumberOfPages />
              </span>
            </div>
            <GoToNextPage>
              {(props) => (
                <Button
                  type="link"
                  className={cn({
                    'text-primary': !props.isDisabled,
                    'text-gray-400': props.isDisabled,
                  })}
                  onClick={props.onClick}
                  icon={<BsChevronDown />}
                  disabled={props.isDisabled}
                  aria-label="Next Page"
                />
              )}
            </GoToNextPage>
          </div>
          <div className="space-x-1 flex items-center pr-6">
            <ZoomOut>
              {(props) => (
                <Button type="link" aria-label="Zoom Out" className="text-primary" onClick={props.onClick} icon={<AiOutlineZoomOut />} />
              )}
            </ZoomOut>
            <Zoom />
            <ZoomIn>
              {(props) => (
                <Button type="link" aria-label="Zoom In" className="text-primary" onClick={props.onClick} icon={<AiOutlineZoomIn />} />
              )}
            </ZoomIn>
          </div>
          {(extra || process.env.NODE_ENV === 'development') && (
            <div className="toolbar-right-container space-x-1 flex items-center pr-2 pl-2">
              {/* TODO - CREATE DOWNLOADABLE LINK OF PDF */}
              {process.env.NODE_ENV === 'development' && (
                <Download>
                  {(props) => <Button type="text" aria-label="Download PDF" onClick={props.onClick} icon={<AiOutlineDownload />} />}
                </Download>
              )}

              {extra}
            </div>
          )}
        </div>
      )
    }}
  </Toolbar>
)

const characterMap: CharacterMap = {
  isCompressed: true,
  // The url has to end with "/"
  url: '/cmaps/',
}

export default function PdfReader({ url = '', toolbarExtra = null as any }) {
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar: (Toolbar) => renderToolbar(Toolbar, toolbarExtra),
  })

  return (
    <Worker workerUrl="/pdf.worker.min.js">
      <div
        className="pdf-container"
        style={{
          height: 'calc(100vh - 68px)',
          width: '900px',
          marginLeft: 'auto',
          marginRight: 'auto',
          maxWidth: '100%',
          maxHeight: '750px',
        }}
      >
        <Viewer fileUrl={url} plugins={[defaultLayoutPluginInstance]} characterMap={characterMap} defaultScale={SpecialZoomLevel.PageFit} />
      </div>
      <style jsx global>{`
        .pdf-container .rpv-default-layout-sidebar {
          display: none;
        }
        .pdf-container .rpv-default-layout-container {
          border-color: #f0f0f0;
        }
      `}</style>
    </Worker>
  )
}
